import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Alert } from 'reactstrap';
import { ConfigManager } from '../Global/SettingsManager';
import { AuthNavigation } from './AuthNavigation';
import { ReactComponent as IcoGovAuthentication } from '../../img/icn/icn_gov-authentication.svg';
import { ReactComponent as IcoSMS } from '../../img/icn/icn_sms.svg';
import ImgCardCaixaIUOnly from '../../img/content/card-caixa-iu_only.png';
import './Authentication.scss';

export class Authentication extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            selectedOption: '',
            configs: {},
            loading: false,
            errors: ''
        };

        this.validateConfig = this.validateConfig.bind(this);
        this.requestFormSubmit = this.requestFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.validateConfig();
    }

    async validateConfig() {
        var configManager = new ConfigManager();
        const _configs = await configManager.getConfigs();
        const _enableSMSAuth = await configManager.enableSMSAuth();

        if (this.state.configs) {
            this.setState({
                configs: {
                    amaLoginRedirect: _configs.amaLogin,
                    isSMSAuthEnable: _enableSMSAuth.isSMSAuthEnable
                }
            });

            if (_enableSMSAuth && _enableSMSAuth.isSMSAuthEnable === false) {
                this.setState({
                    selectedOption: 'govAuthentication'
                });
            }
        }
    }

    requestFormSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });
        this.submitFormData();
    }

    async submitFormData() {
        if (this.state.selectedOption === 'tokenSMS') {
            this.props.history.push('/Authentication/Token');
        } else if (this.state.selectedOption === 'govAuthentication') {
            if (this.state.configs && this.state.configs.amaLoginRedirect) {
                window.location.href = this.state.configs.amaLoginRedirect;
            } else {
                this.setState({
                    loading: false,
                    errors: this.props.amaErrorResources.Generic
                });
            }
        } else {
            this.setState({
                loading: false,
                errors: this.props.formResources.FormErrorChooseAnOption
            });
        }
    }

    handleChange(event) {
        this.setState({
            selectedOption: event.target.value
        });
    }

    render() {
        const { selectedOption, configs, errors, loading } = this.state;
        const { globalResources, formResources } = this.props;

        return (
            <main className="page-content authentication">
                <Container>
                    <Row>
                        <Col lg="5">
                            <h1>{formResources.AuthenticationTitle}</h1>

                            <Form className="needs-validation" noValidate>
                                <FormGroup tag="fieldset" className="mb-0 min-lg-h300">
                                    <legend className="text-primary"><strong>{formResources.AuthenticationLegend}</strong><br></br><strong>{formResources.AuthenticationByToken2Title}</strong></legend>
                                    {errors &&
                                        <Alert key={errors} color="danger"><div dangerouslySetInnerHTML={{ __html: errors }} /></Alert>
                                    }

                                    <div className="form-check form-check-img">
                                        <input type="radio" name="authentication" id="govAuthentication" className="form-check-input" value="govAuthentication" checked={configs && configs.isSMSAuthEnable ? selectedOption === 'govAuthentication' : true} onChange={this.handleChange} required={true} />
                                        <label htmlFor="govAuthentication" className="form-check-label">
                                            <IcoGovAuthentication className="form-check-img__ico" width="50" height="50" alt="" />
                                            <div className="form-check-img__label">
                                                <strong className="form-label text-uppercase">{formResources.FormLabelGovAuthentication}<br /></strong>
                                                <small dangerouslySetInnerHTML={{ __html: formResources.FormHelperGovAuthenticationCode }}></small>
                                            </div>
                                        </label>
                                    </div>

                                    {configs && configs.isSMSAuthEnable &&
                                        <div className="form-check form-check-img">
                                            <input type="radio" name="authentication" id="tokenSMS" className="form-check-input" value="tokenSMS" checked={selectedOption === 'tokenSMS'} onChange={this.handleChange} required={true} />
                                            <label htmlFor="tokenSMS" className="form-check-label">
                                                <IcoSMS className="form-check-img__ico" width="37" height="61" alt="" />
                                                <div className="form-check-img__label">
                                                    <strong className="form-label text-uppercase">{formResources.FormLabelSMS}<br /></strong>
                                                    <small>{formResources.FormHelperSMSCode}</small>
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </FormGroup>

                                <AuthNavigation analyticsId="continue_1" linkURL={"/"} linkText={globalResources.NavAuthBackText} buttonName={globalResources.NavForwardText} buttonClickFunction={this.requestFormSubmit} buttonDisable={!selectedOption} loading={loading} loadingText={globalResources.LoadingPageText} arrowIcon={true} />
                            </Form>
                        </Col>
                        <Col lg="7" className="text-center text-lg-right">
                            <p><img className="img" src={ImgCardCaixaIUOnly} alt="" /></p>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}