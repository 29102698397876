import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import TooltipItem from '../../Fields/TooltipItem';
import ImgCaixaDiretaLogo from '../../../img/content/app-caixadireta_logo.png';
import ImgCaixaDireta from '../../../img/content/app-caixadireta1.png';
import AppStore from '../../../img/icn/icn_app-store.png';
import GooglePlay from '../../../img/icn/icn_google-play.png';
import AppGallery from '../../../img/icn/icn_app-gallery.png';

export class CaixaDireta extends Component {
    static displayName = CaixaDireta.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            isDesktop: window.innerWidth >= 992 ? true : false
        }
    }

    render() {
        const { globalResources, formResources } = this.props;

        return (
            <section className="product__caixadireta">
                <Row className="mx-0">
                    <Col xs="11" lg="7">
                        <div className="pl-5 mb-5">
                            <h2 className="h3 text-dark mb-3 icn-position"><span className="icn-approved"></span><strong>{formResources.CaixaDiretaTitle}</strong><TooltipItem key="FormTooltipCaixaDireta" id="FormTooltipCaixaDireta" text={formResources.FormTooltipCaixaDireta} /><br /></h2>
                            <div className="mb-4 mr-5 pr-5" dangerouslySetInnerHTML={{ __html: formResources.CaixaDiretaDescription3 }} />
                            <img className="img mb-2" src={ImgCaixaDiretaLogo} width="45" alt="" />
                            <h3 className="h6 text-dark mb-3"><strong>{formResources.DownloadAppsText}</strong></h3>
                            <Row className="icn-apps">
                                <Col xs="6" sm="auto" className="pr-1">
                                    <a href="https://apps.apple.com/pt/app/caixadirecta/id1369089471" target="_blank" rel="external noopener noreferrer"><img className="img" src={AppStore} alt={globalResources.AppStoreAltText} /></a>
                                </Col>
                                <Col xs="6" sm="auto" className="pl-1 pr-3 pr-md-1">
                                    <a href="https://play.google.com/store/apps/details?id=cgd.pt.caixadirectaparticulares&hl=pt_PT" target="_blank" rel="external noopener noreferrer"><img className="img" src={GooglePlay} alt={globalResources.GooglePlayAltText} /></a>
                                </Col>
                                <Col xs="6" sm="auto" className="pt-2 pt-md-0 pt-xl-0 pl-md-1 pl-xl-1 pr-1">
                                    <a href="https://appgallery7.huawei.com/#/app/C102312221" target="_blank" rel="external noopener noreferrer"><img className="img" src={AppGallery} alt={globalResources.AppGalleryAltText} /></a>
                                </Col>
                            </Row>

                            <div className="mb-3 ml-5 mt-5 title-info-docs" dangerouslySetInnerHTML={{ __html: formResources.TitleInfoDocs }} />
                            <div className="mb-5 pl-5" dangerouslySetInnerHTML={{ __html: formResources.ContaCaixaDiretaInfoDocs }} />
                        </div>

                    </Col>
                    <Col xs="11" lg="5" className="text-center mt-3 pr-1">
                        <img className="img img-caixadireta" src={ImgCaixaDireta} alt="" />
                    </Col>
                </Row>
            </section>
        );
    }
}
